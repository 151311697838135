import classNames from 'classnames';
import logoEm from 'assets/svg/eml-logo.svg';

export type LogoProps = {
  baseline: string;
  className?: string;
};

export const Logo = ({ baseline, className }: LogoProps): JSX.Element => {
  return <img className={classNames('Logo-em', className)} src={logoEm} alt={baseline} />;
};
