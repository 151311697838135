import { useCallback } from 'react';
import { Formik } from 'formik';
import Link from 'components/utils/Link';
import FormField from 'components/form/FormField';
import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import TextInput from 'components/form/TextInput';
import Form from 'components/form/Form';
import yup from 'components/form/yup';
import FormFieldset from 'components/form/FormFieldset';
import './LoginForm.scss';
import FormError from 'components/form/FormError';
import Anchor from 'components/ui/Anchor';
import Icon from 'components/ui/Icon';
import Tick from 'components/ui/Icon/actions/Tick';

export type ResetPasswordFormProps = {
  resetUrl?: string;
  submitting: boolean;
  success: boolean;
  errors: React.ReactNode[];
  messages: {
    title: React.ReactNode;
    email: React.ReactNode;
    submit: React.ReactNode;
    success: React.ReactNode;
    cancel: React.ReactNode;
    loading: string;
  };
  onSubmit(crendentials: { email: string }): void;
};

const credentialsSchema = yup.object({
  email: yup.string().email().required(),
});

type CredentialsSchema = yup.InferType<typeof credentialsSchema>;

function ResetPasswordForm({
  resetUrl,
  submitting,
  errors,
  messages,
  success,
  onSubmit,
}: ResetPasswordFormProps): JSX.Element {
  const handleSubmit = useCallback(
    (values: CredentialsSchema) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <div className="LoginForm">
      <div className="LoginForm-login">
        <Formik<CredentialsSchema>
          initialValues={{ email: '' }}
          validationSchema={credentialsSchema}
          onSubmit={handleSubmit}
        >
          <Form className="LoginForm-form">
            {errors.length ? (
              <div className="LoginForm-errors">
                {errors.map((error, i) => (
                  <FormError key={i}>{error}</FormError>
                ))}
              </div>
            ) : null}
            <FormFieldset label={messages.title} labelProps={{ hidden: true }}>
              <FormField label={messages.email}>
                <TextInput name="email" type="email" size="lg" />
              </FormField>
            </FormFieldset>
            <div className="LoginForm-actions is-inline">
              <Anchor kind="tertiary" as={<Link to="/" />}>
                {messages.cancel}
              </Anchor>
              <Button
                className="LoginForm-submit"
                kind="primary"
                type="submit"
                variant="squared"
                size="xl"
                disabled={submitting}
              >
                {messages.submit}
              </Button>
            </div>
            {submitting ? (
              <Spinner
                kind="primary"
                style={{ margin: '1em auto', display: 'block' }}
                aria-label={messages.loading}
              />
            ) : null}
            {success ? (
              <p className="LoginForm-success">
                <Icon as={Tick} />
                {messages.success}
              </p>
            ) : null}
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
