import { ErrorModel, UserSettingsModel, UserModel } from 'models';
import { LocaleCode } from 'services/localeHelper';

export function requestUser() {
  return {
    type: requestUser.type,
  } as const;
}

requestUser.type = 'user/request' as const;

export function resetUser() {
  return {
    type: resetUser.type,
  } as const;
}

resetUser.type = 'user/reset' as const;

export function receiveUser(user: UserModel) {
  return {
    type: receiveUser.type,
    payload: {
      user,
      receivedAt: Date.now(),
    },
  } as const;
}

receiveUser.type = 'user/receive' as const;

export function patchUser(user: Partial<UserModel>) {
  return {
    type: patchUser.type,
    payload: user,
  } as const;
}

patchUser.type = 'user/patch' as const;

export function errorUser(error: ErrorModel) {
  return {
    type: errorUser.type,
    payload: {
      error,
      receivedAt: Date.now(),
    },
  } as const;
}

errorUser.type = 'user/error' as const;

export function updateUser(settings: Partial<UserSettingsModel>) {
  return {
    type: updateUser.type,
    payload: {
      settings,
    },
  } as const;
}

updateUser.type = 'user/update' as const;

export function setUserSettings(settings: Partial<UserSettingsModel>) {
  return {
    type: setUserSettings.type,
    payload: {
      settings,
    },
  } as const;
}

setUserSettings.type = 'user/settings/set' as const;

export function setUserLocale(locale: LocaleCode) {
  return {
    type: setUserLocale.type,
    payload: {
      locale,
    },
  };
}

setUserLocale.type = 'user/locale/set' as const;
