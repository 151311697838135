import { useCallback, useState } from 'react';
import { Formik } from 'formik';
import Link from 'components/utils/Link';
import FormField from 'components/form/FormField';
import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import TextInput from 'components/form/TextInput';
import Form from 'components/form/Form';
import yup from 'components/form/yup';
import FormFieldset from 'components/form/FormFieldset';
import FormError from 'components/form/FormError';
import Anchor from 'components/ui/Anchor';
import './LoginForm.scss';
import classNames from 'classnames';

export type LoginFormProps = {
  resetUrl?: string;
  submitting: boolean;
  errors: React.ReactNode[];
  messages: {
    instructionText: React.ReactNode;
    title: React.ReactNode;
    email: React.ReactNode;
    password: React.ReactNode;
    submit: React.ReactNode;
    passwordForgot: React.ReactNode;
    loading: string;
    applicant: React.ReactNode;
    student: React.ReactNode;
    staffMember: React.ReactNode;
  };
  onSubmit(crendentials: { email: string; password: string }): void;
  onExternalSubmit(): void;
};

const credentialsSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

type CredentialsSchema = yup.InferType<typeof credentialsSchema>;

function LoginForm({
  resetUrl,
  submitting,
  errors,
  messages,
  onSubmit,
  onExternalSubmit,
}: LoginFormProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const handleExternalSubmit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      onExternalSubmit();
    },
    [onExternalSubmit],
  );

  const handleSubmit = useCallback(
    (values: CredentialsSchema) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <div className="LoginForm">
      <div className="LoginForm-choices">
        <button
          className={classNames('LoginForm-choice', {
            'is-active': isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls="#login-form"
        >
          {messages.applicant}
        </button>
        <button className="LoginForm-choice" onClick={handleExternalSubmit}>
          {messages.student}
        </button>
        <button className="LoginForm-choice" onClick={handleExternalSubmit}>
          {messages.staffMember}
        </button>
      </div>

      <div className="LoginForm-login" id="login-form" hidden={!isOpen}>
        <Formik<CredentialsSchema>
          initialValues={{ email: '', password: '' }}
          validationSchema={credentialsSchema}
          onSubmit={handleSubmit}
        >
          <Form className="LoginForm-form">
            {errors.length ? (
              <div className="LoginForm-errors">
                {errors.map((error, i) => (
                  <FormError key={i}>{error}</FormError>
                ))}
              </div>
            ) : null}
            <FormFieldset label={messages.title} labelProps={{ hidden: true }}>
              <FormField label={messages.email}>
                <TextInput name="email" type="email" autoComplete="username" size="lg" />
              </FormField>
              <FormField label={messages.password}>
                <TextInput
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  size="lg"
                />
              </FormField>
            </FormFieldset>
            <div className="LoginForm-actions">
              {resetUrl ? (
                <Anchor kind="tertiary" className="LoginForm-reset" as={<Link to={resetUrl} />}>
                  {messages.passwordForgot}
                </Anchor>
              ) : null}
              <Button
                className="LoginForm-submit"
                kind="primary"
                type="submit"
                disabled={submitting}
                size="xl"
                variant="squared"
              >
                {messages.submit}
              </Button>
            </div>
            {submitting ? (
              <Spinner
                kind="primary"
                style={{ margin: '1em auto', display: 'block' }}
                aria-label={messages.loading}
              />
            ) : null}
            <div className="LoginForm-instruction-text">{messages.instructionText}</div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default LoginForm;
