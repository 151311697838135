import { persistReducer } from 'redux-persist';
import {
  requestUser,
  resetUser,
  receiveUser,
  errorUser,
  updateUser,
  setUserSettings,
  patchUser,
  setUserLocale,
} from 'actions/userActions';
import { findDefaultTimezone } from 'services/localeHelper';
import { ErrorModel, UserModel } from 'models';
import { createReducer, createPersistConfig } from './reducerUtils';

export type UserState = Readonly<{
  user: UserModel;
  isFetching: boolean;
  error: ErrorModel | null;
}>;

const initialState: UserState = {
  user: {
    userId: null,
    upn: null,
    firstName: null,
    lastName: null,
    campus: null,
    program: null,
    profile: null,
    studyYear: null,
    status: null,
    settings: {
      campus: null,
      locale: null,
      timezone: findDefaultTimezone(),
      favoriteServices: [],
      hiddenBlocks: [],
      shownBlocks: [],
      calendars: [],
      hasReadPolicy: true,
      hasSeenProfileSwitcher: false,
    },
    externalData: {},
    additionalData: {},
    addresses: [],
    activities: [],
  },
  isFetching: false,
  error: null,
};

function requestUserReducer(state: UserState, action: ReturnType<typeof requestUser>) {
  return {
    ...state,
    isFetching: true,
  };
}

function resetUserReducer(state: UserState, action: ReturnType<typeof resetUser>) {
  return {
    ...state,
    isFetching: false,
  };
}

function receiveUserReducer(state: UserState, action: ReturnType<typeof receiveUser>) {
  const user = { ...state.user, ...action.payload.user };

  // Set default campus if not yet defined
  if (!user.settings.campus && user.campus) {
    user.settings.campus = user.campus.code;
  }

  return {
    ...state,
    user,
    error: null,
    isFetching: false,
  };
}

function patchUserReducer(state: UserState, action: ReturnType<typeof patchUser>) {
  const user = { ...state.user, ...action.payload };

  return {
    ...state,
    user,
  };
}

function updateUserReducer(state: UserState, action: ReturnType<typeof updateUser>) {
  return {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        ...action.payload.settings,
      },
    },
    error: null,
    isFetching: false,
  };
}

function errorUserReducer(state: UserState, action: ReturnType<typeof errorUser>) {
  return {
    ...state,
    error: action.payload.error,
    isFetching: false,
  };
}

function setUserSettingsReducer(state: UserState, action: ReturnType<typeof setUserSettings>) {
  return {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        ...action.payload.settings,
      },
    },
  };
}

function setUserLocaleReducer(state: UserState, action: ReturnType<typeof setUserLocale>) {
  return {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        ...action.payload,
      },
    },
  };
}

export const persistConfig = createPersistConfig('user', {
  whitelist: ['user'],
});

export default persistReducer(
  persistConfig,
  createReducer(initialState, {
    [requestUser.type]: requestUserReducer,
    [resetUser.type]: resetUserReducer,
    [receiveUser.type]: receiveUserReducer,
    [patchUser.type]: patchUserReducer,
    [updateUser.type]: updateUserReducer,
    [errorUser.type]: errorUserReducer,
    [setUserSettings.type]: setUserSettingsReducer,
    [setUserLocale.type]: setUserLocaleReducer,
  }),
);
