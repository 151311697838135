import classNames from 'classnames';
import { Override } from 'types';
import './LangSwitcher.scss';
import { LocaleCode } from 'services/localeHelper';
import { useDispatch } from 'react-redux';
import { setUserLocale } from 'actions/userActions';
import { useSelector } from 'react-redux';
import { selectLocale } from 'selectors/userSelectors';

type LangSwitcherProps = Override<
  React.ComponentPropsWithoutRef<'nav'>,
  {
    locales?: LocaleCode[];
    messages: {
      label: string;
      fr: string;
      en: string;
    };
  }
>;

export const LangSwitcher = ({
  className,
  messages,
  locales = ['fr', 'en'],
}: LangSwitcherProps): JSX.Element => {
  const dispatch = useDispatch();
  const userLocale = useSelector(selectLocale);

  return (
    <nav
      className={classNames('LangSwitcher', className)}
      role="navigation"
      aria-label={messages.label}
    >
      <ul className="LangSwitcher-list">
        {locales.map((locale) => (
          <li className="LangSwitcher-item" key={locale}>
            <button
              className={classNames('LangSwitcher-lang', { 'is-active': userLocale === locale })}
              onClick={() => dispatch(setUserLocale(locale as LocaleCode))}
            >
              <span aria-hidden>{locale}</span>
              <span className="u-srOnly">{messages[locale]}</span>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
