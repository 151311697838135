import React from 'react';
import classNames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import Overtitle from './Overtitle';
import Subtitle from './Subtitle';
import './Title.scss';

export type TitleSize = 1 | 2 | 3 | 4 | 5 | 6 | '1' | '2' | '3' | '4' | '5' | '6';
export type TitleColors = 'primary' | 'secondary' | 'white' | 'black';
type TitleAlignments = 'center' | 'right';

export type TitleProps<Component> = OverridableComponentProps<
  Component,
  'h1',
  {
    size?: TitleSize;
    kind?: TitleColors;
    alignment?: TitleAlignments;
    overtitle?: React.ReactNode;
    subtitle?: React.ReactNode;
    icon?: React.ReactElement;
    children?: React.ReactNode;
    actions?: React.ReactNode;
    className?: string;
  }
>;

function Title<C>({
  as: As = 'h1',
  size,
  kind,
  alignment,
  overtitle,
  subtitle,
  icon,
  children,
  actions,
  className,
  ...props
}: TitleProps<C>): JSX.Element {
  const rootClasses = classNames('Title', className, {
    [`is-${kind}`]: Boolean(kind),
    [`Title-h${size}`]: Boolean(size),
  });

  return (
    <div className={rootClasses} {...props}>
      <div className={classNames('Title-content', [alignment && `is-${alignment}`])}>
        {overtitle ? <Overtitle>{overtitle}</Overtitle> : null}
        <As className="Title-hX">
          {icon ? (
            <icon.type {...icon.props} className={classNames('Title-icon', icon.props.className)} />
          ) : null}
          {children}
        </As>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </div>
      {actions ? <div className="Title-actions">{actions}</div> : null}
    </div>
  );
}

export default Title;
