import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectResetUrl } from 'selectors/configSelectors';
import ResetPasswordForm from 'components/ui/compositions/LoginForm/ResetPasswordForm';
import LandingLayout from 'components/ui/compositions/LandingLayout/LandingLayout';
import useMessages from 'components/hooks/useMessages';
import useTranslate from 'components/hooks/useTranslate';
import useLocale from 'components/hooks/useLocale';
import { formatError } from 'components/hooks/useErrorMessage';
import cdmApi, { resolveError } from 'services/cdmApi';
import { ErrorModel } from 'models';

function ResetPasswordPage(): JSX.Element {
  const t = useTranslate();
  const locale = useLocale();
  const [error, setError] = useState<ErrorModel | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const resetUrl = useSelector(selectResetUrl);
  const messages = useMessages((t) => {
    return {
      // Landing page messages
      title: t('login.title'),
      caption: t('reset_pass.text'),
      baseline: t('identity.baseline'),
      home: t('identity.home'),
      // Login form messages
      email: t('login.email'),
      cancel: t('label.cancel'),
      submit: t('reset_pass.submit'),
      success: t('reset_pass.success'),
      loading: t('label.loading'),
      langswitcher: {
        label: t('langswitcher.label'),
        fr: t('langswitcher.fr'),
        en: t('langswitcher.en'),
      },
    };
  }, []);

  const handleEmailSubmit = useCallback(
    async ({ email }: { email: string }) => {
      setLoading(true);
      setSuccess(false);

      try {
        await cdmApi.post('/user/password', { email, locale });

        setSuccess(true);
      } catch (e) {
        setError(resolveError(e));
      } finally {
        setLoading(false);
      }
    },
    [locale],
  );

  const errors = [];

  if (error) {
    if (error.error === 'user:reset_pass:cannot_send_email') {
      errors.push(<p>{t('error.user:reset_pass:cannot_send_email')}</p>);
    } else if (error.error === 'auth:firebase_email_not_found') {
      errors.push(<p>{t('error.auth:firebase_email_not_found')}</p>);
    } else {
      const errorMessage = formatError(t, error);
      const errorElement =
        typeof errorMessage === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        ) : (
          <div>{errorMessage}</div>
        );

      errors.push(errorElement);
    }
  }

  return (
    <LandingLayout
      side="center"
      messages={messages}
      className={'resetPasswordPage'}
      titleProps={{ alignment: 'center' }}
      displayLangSwitcher
      displayLogoBeforeTitle
    >
      <ResetPasswordForm
        resetUrl={resetUrl}
        submitting={loading}
        onSubmit={handleEmailSubmit}
        errors={errors || null}
        messages={messages}
        success={success}
      />
    </LandingLayout>
  );
}

export default ResetPasswordPage;
