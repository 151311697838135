import Overlay from 'components/ui/Overlay';
import image from 'assets/img/login.jpg';
import imageMobile from 'assets/img/login-mobile.jpg';
import imageTablet from 'assets/img/login-tablet.jpg';
import { Override } from 'types';
import classNames from 'classnames';
import Title from 'components/ui/Title';
import './LandingLayout.scss';
import { TitleProps } from 'components/ui/Title/Title';
import { Logo } from 'components/ui/Logo/Logo';
import { LangSwitcher } from 'components/ui/LangSwitcher/LangSwitcher';

export type LandingLayoutSide = 'right' | 'center' | 'left';

export type LandingLayoutProps = Override<
  React.ComponentPropsWithRef<'div'>,
  {
    side?: LandingLayoutSide;
    titleProps?: Partial<TitleProps<'h1'>>;
    messages: {
      baseline: string;
      home?: string;
      title?: string;
      caption?: string;
      langswitcher?: {
        label: string;
        fr: string;
        en: string;
      };
    };
    displayLangSwitcher?: boolean;
    displayLogoBeforeTitle?: boolean;
  }
>;

function LandingLayout({
  messages,
  children,
  titleProps,
  className,
  displayLangSwitcher = false,
  displayLogoBeforeTitle = false,
  side,
  ...props
}: LandingLayoutProps): JSX.Element {
  return (
    <section className="LandingLayout-wrapper">
      <div
        className={classNames('LandingLayout', className, {
          [`is-${side}`]: Boolean(side),
        })}
        {...props}
      >
        {displayLangSwitcher && messages?.langswitcher && (
          <LangSwitcher className="is-floatingMd" messages={messages.langswitcher} />
        )}
        {displayLogoBeforeTitle && (
          <Logo className="is-big is-center" baseline={messages.baseline} />
        )}
        {messages.title ? (
          <Title className="LandingLayout-title" {...titleProps}>
            {messages.title}
          </Title>
        ) : null}
        {messages.caption ? (
          <div className="LandingLayout-caption">
            <p>{messages.caption}</p>
          </div>
        ) : null}
        <div className="LandingLayout-content">{children}</div>
      </div>
      <Overlay>
        {!displayLogoBeforeTitle && <Logo baseline={messages.baseline} />}
        <img
          src={image}
          srcSet={`${imageMobile} 480w, ${imageTablet} 768w, ${image} 1280w`}
          alt="Login"
        />
      </Overlay>
    </section>
  );
}

export default LandingLayout;
